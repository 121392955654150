var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('h1',[_vm._v("Права доступа")]),_c('v-btn',{attrs:{"depressed":"","color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.saveAccessRights()}}},[_vm._v(" Сохранить ")])],1),_c('section',{staticClass:"mt-5"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableAccessRights,"items":_vm.filteredAccessrights,"items-per-page":-1,"loading":_vm.loading,"loading-text":"Загрузка...","no-data-text":"Похоже тут ничего нет","footer-props":{
        itemsPerPageText: 'Элементов в таблице',
        itemsPerPageAllText: 'Все',
        itemsPerPageOptions: [5, 10, 15, -1]
      }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex ma-5"},[_c('div',{staticClass:"mt-5"},[_c('v-select',{attrs:{"items":_vm.userGroups,"item-text":"title","item-value":"group_id","label":"Группа","append-outer-icon":"mdi-account-key-outline"},model:{value:(_vm.simpleFilter),callback:function ($$v) {_vm.simpleFilter=$$v},expression:"simpleFilter"}})],1)])]},proxy:true},{key:"item.controller",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.controller))])]}},{key:"item.group",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.group))])]}},{key:"item.C",fn:function(ref){
      var item = ref.item;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.C),expression:"item.C"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(item.C)?_vm._i(item.C,null)>-1:(item.C)},on:{"change":function($event){var $$a=item.C,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "C", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "C", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "C", $$c)}}}})]}},{key:"item.R",fn:function(ref){
      var item = ref.item;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.R),expression:"item.R"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(item.R)?_vm._i(item.R,null)>-1:(item.R)},on:{"change":function($event){var $$a=item.R,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "R", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "R", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "R", $$c)}}}})]}},{key:"item.U",fn:function(ref){
      var item = ref.item;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.U),expression:"item.U"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(item.U)?_vm._i(item.U,null)>-1:(item.U)},on:{"change":function($event){var $$a=item.U,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "U", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "U", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "U", $$c)}}}})]}},{key:"item.D",fn:function(ref){
      var item = ref.item;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.D),expression:"item.D"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(item.D)?_vm._i(item.D,null)>-1:(item.D)},on:{"change":function($event){var $$a=item.D,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "D", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "D", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "D", $$c)}}}})]}},{key:"item.is_new",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"actions"},[(item.is_new)?_c('div',[_c('v-icon',{staticClass:"red-option"},[_vm._v(" mdi-information-outline ")]),_vm._v(" Новое правило ")],1):_c('div',[_c('v-icon',{staticClass:"default"},[_vm._v(" mdi-check-circle-outline ")]),_vm._v(" Правило обработано ")],1)])]}}],null,true)})],1),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"mt-4"},[_c('p',{staticClass:"warning-alert"},[_vm._v("Мы настоятельно не рекомендует давать право управлять разделом \"Права доступа\" кому-то кроме администратора. Это может создавать неприятные ситуации, ибо группа автоматически становится по возможностям равной Администратору. Но кто мы такие, чтобы кого-то ограничивать, поэтому данный функционал существует.")])])}]

export { render, staticRenderFns }
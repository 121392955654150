
























































































import { Vue, Component } from 'vue-class-decorator'
import { GlobalModule, AccessRightsModule, UserModule } from '@/store'
import { Payload } from 'vue/interfaces'

@Component({
  metaInfo: {
    title: 'Права доступа'
  }
})
export default class AccessRights extends Vue {
  public tableAccessRights: Array<object>
  public simpleFilter: number

  // Data
  constructor() {
    super()
    this.simpleFilter = 1
    this.tableAccessRights = [
      {
        text: 'Модуль',
        value: 'controller',
        align: 'start',
        sortable: false
      },
      {
        text: 'Группа',
        value: 'group',
        align: 'start',
        sortable: false
      },
      {
        text: 'Создание',
        value: 'C',
        align: 'start',
        sortable: false
      },
      {
        text: 'Чтение',
        value: 'R',
        align: 'start',
        sortable: false
      },
      {
        text: 'Обновление',
        value: 'U',
        align: 'start',
        sortable: false
      },
      {
        text: 'Удаление',
        value: 'D',
        align: 'start',
        sortable: false
      },
      {
        text: 'Статус',
        value: 'is_new',
        align: 'start',
        sortable: false
      }
    ]
  }

  // Store init
  @GlobalModule.Getter('getLoading') loading!: boolean
  @GlobalModule.Action('signalRenderMenuNow') signalRenderMenuNow!: () => Promise<void>

  @AccessRightsModule.Action('requestAccessRights') loadAccessRights!: () => Promise<void>
  @AccessRightsModule.Action('saveAccessRights') storeAccessRights!: (payload: object) => Promise<void>
  @AccessRightsModule.Getter('getAccessRights') accessrights!: Array<object>
  @AccessRightsModule.Action('getPageDataForModule') loadUserGroups!: () => Promise<void>

  @UserModule.Getter('getUserGroups') userGroups!: Array<object>

  @UserModule.Getter('userInfo') user!: {[propName: string]: any }

  get filteredAccessrights() {
    let filteredData: Array<{[propName: string]: any }> = []
    if (this.simpleFilter !== 0) {
      const findIndex = this.simpleFilter
      const newArrayByfindIndex = this.accessrights.filter((item: {[propName: string]: any }) => parseInt(item.group_id) === findIndex)
      filteredData = newArrayByfindIndex
    } else {
      filteredData = this.accessrights
    }
    return filteredData
  }

  loadAccessRightsList() {
    this.loadAccessRights()
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  loadPageData() {
    this.loadUserGroups()
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  saveAccessRights() {
    const findAllAccessRight = this.accessrights.filter((controller: {[propName: string]: any }) => controller.server_controller === 'Controller_Access_Rights') as {[propName: string]: any }
    if (!findAllAccessRight) {
      this.$noty('error', 'Критическая ошибка системы, файл Controller_Access_Rights не найден!', 5000).show()
      return false
    }

    const userGroupID: number = this.user.group.group_id
    const findAccessRight = findAllAccessRight.find((controller: {[propName: string]: any }) => controller.group_id === userGroupID)
    if (findAccessRight.group_id === userGroupID && userGroupID === 1 && (!findAccessRight.R || !findAccessRight.U)) {
      this.$noty('error', 'Нельзя решать доступа администратора к модулю права доступа!', 5000).show()
      return false
    }

    const payload = {
      data: JSON.stringify(this.accessrights)
    } as Payload
    this.storeAccessRights(payload)
      .then(() => {
        this.$noty('success', 'Права доступа успешно обновлены!', 5000).show()
        if (findAccessRight.group_id === userGroupID && (!findAccessRight.R || !findAccessRight.U)) {
          this.signalRenderMenuNow()
          this.$router.push('/')
        } else {
          this.loadAccessRightsList()
          this.signalRenderMenuNow()
        }
      })
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  // Hooks
  async created() {
    this.loadPageData()
    this.loadAccessRightsList()
  }
}
